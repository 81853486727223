import axios from 'axios';

export default async function Axios(config) {
    const axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_API_HOST,
    })
    const headers = {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': "*",
        // 'Access-Control-Allow-Methods': "GET, PUT, POST, DELETE, OPTIONS, post, get"
    }

    // console.log(header)
    const header = {
        ...config,
        headers
    }

    return axiosInstance.request(header);
}
