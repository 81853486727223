import API from '../../api/index';

const state = {
  resetPasswordLoading: true,
  resetPasswordSuccess: false,
  resetPasswordError: null,
};

const getters = {
  resetPasswordLoading: (state) => state.resetPasswordLoading,
  resetPasswordError: (state) => state.resetPasswordError,
  resetPasswordSuccess: (state) => state.resetPasswordSuccess,
};

const actions = {
  resetPassword({ commit }, data) {
    if (!data?.token) {
      commit("errorReset", "Token Tidak Tersedia");
    }

    setTimeout(() => {
      return API({
        method: 'POST',
        url: '/v1/auths/reset-password/verify',
        data: {
          token: data.token,
          newPassword: data.newPassword
        },
      }).then(() => {
        commit("successReset");
      }).catch((err) => {
        const error = err.response.data.error ?? "Terjadi Kesalahan";
        
        commit("errorReset", error);
      });
    }, 100);
  },
  resetPasswordErrorState({ commit }) {
    commit("errorResetState")
  }
};

const mutations = {
  successReset: (state) => {
    state.resetPasswordSuccess = true;
    state.resetPasswordLoading = false;
  },
  errorReset: (state, error) => {
    state.resetPasswordLoading = false;
    state.resetPasswordError = error;
  },
  errorResetState: (state) => {
    // state.resetPasswordLoading = false;
    state.resetPasswordError = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
