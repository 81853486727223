<template>
  <img
    src="./../assets/images/logo.png"
    class="logo"
    alt=""
    width="100"
    style="margin: 40px"
  />
  <footer id="footer">
    <div v-if="!this.$route.query.token">
      <h1 style="color: white !important; font-size: 40px !important">
        Reset Token Tidak Valid
      </h1>
    </div>

    <div v-if="this.$route.query.token" class="inner">
      <div v-if="this.resetPasswordSuccess" class="toast">
        <h1 style="color: white !important; font-size: 40px !important">
          Atur Ulang Kata Sandi Berhasil
        </h1>
      </div>

      <div v-if="!this.resetPasswordSuccess" class="toast">
        <h1 style="color: white !important; font-size: 40px !important">
          Atur Ulang Kata Sandi
        </h1>

        <form @submit.prevent="submitForm">
          <div class="field first">
            <span v-if="errors.password" class="error">{{
              errors.password
            }}</span>
            <label for="name">Kata Sandi</label>
            <input
              v-model="formData.password"
              name="password"
              id="password"
              type="password"
              placeholder="Kata Sandi Baru"
            />
          </div>
          <div class="field">
            <span v-if="errors.passwordRepeat" class="error">{{
              errors.passwordRepeat
            }}</span>
            <label for="phone">Konfirmasi Kata Sandi</label>
            <input
              v-model="formData.passwordRepeat"
              name="passwordRepeat"
              id="passwordRepeat"
              type="password"
              placeholder="Konfirmasi Kata Sandi Baru"
            />
          </div>
          <ul class="actions">
            <li>
              <input
                value="Reset Kata Sandi"
                class="button alt"
                type="submit"
              />
            </li>
          </ul>
          <div v-if="this.resetPasswordError" class="toast">
            Atur Ulang Kata Gagal. Silahkan Coba Lagi.
            <br>
            <br>
            <div>{{ this.resetPasswordError }}</div>
          </div>
        </form>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      formData: {
        password: "",
        passwordRepeat: "",
      },
      errors: {},
      showToast: false,
      token: false,
    };
  },
  computed: {
    ...mapGetters([
      "resetPasswordLoading",
      "resetPasswordError",
      "resetPasswordSuccess",
    ]),
  },
  watch: {
    resetPasswordError(val) {
      if (val !== null) {
        setTimeout(() => {
          this.resetPasswordErrorState();
        }, 2000);
      }
    },
  },
  methods: {
    ...mapActions(["resetPassword", "resetPasswordErrorState"]),
    validateForm() {
      const errors = {};
      if (!this.formData.password) {
        errors.password = "Kata Sandi Wajib di Isi";
      }
      if (!this.formData.passwordRepeat) {
        errors.passwordRepeat = "Konfirmasi Kata Sandi Wajib di Isi";
      }
      if (this.formData.password !== this.formData.passwordRepeat) {
        errors.passwordRepeat = "Kata Sandi Tidak Sama";
      }

      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    submitForm() {
      if (this.validateForm()) {
        this.resetPassword({
          token: this.$route.query.token,
          newPassword: this.formData.password,
        });
      }
    },
  },
  components: {},
  mounted() {
    if (!this.$route.query.token) {
      this.errors.token = "Token Not Ready";
    }
  },
};
</script>

<style>
/* Styling untuk halaman */

.container {
  /* max-width: 600px; */
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
h2 {
  color: #e77326 !important;
  /* font-size: 28px; */
  font-weight: bold;
  /* margin-bottom: 20px; */
}
p {
  color: #e77326 !important;
  /* margin-bottom: 20px; */
}
</style>
