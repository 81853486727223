<template>
  <!-- Banner -->
  <section id="banner">
    <div class="inner">
      <header>
        <img
          src="./../assets/images/logo.png"
          class="logo"
          alt=""
          width="15%"
        />
        <h1>Buka Bengkel</h1>
        <h3>Naikkan level penjualan toko Anda dengan Buka Bengkel</h3>
      </header>
      <div class="flex"></div>
      <div class="flex">
        <div>
          <span class="icon fa-pencil-square-o"></span>
          <h3>Pencatatan Stock</h3>
          <p>Kelola stok dengan mudah</p>
        </div>

        <div>
          <span class="icon fa-clock-o"></span>
          <h3>Notifikasi</h3>
          <p>Terus terhubung dengan bisnis Anda</p>
        </div>

        <div>
          <span class="icon fa-book"></span>
          <h3>Buku Hutang</h3>
          <p>Manajemen hutang efektif</p>
        </div>
      </div>

      <footer>
        <!-- <a href="#" class="button">Get Started</a> -->
      </footer>
    </div>
  </section>

  <!-- Three -->
  <section id="three" class="wrapper align-center">
    <div class="inner">
      <div class="flex flex-2">
        <article>
          <div class="image">
            <img
              src="https://ik.imagekit.io/bkbkl/bukabengkel/product_list.png?updatedAt=1681744214533"
              alt="Pic 01"
              width="250px"
            />
          </div>
          <header>
            <h3>Manajemen Produk</h3>
          </header>
          <p>
            Fitur stok Buka Bengkel memberikan <br />
            Anda gambaran yang jelas tentang <br />
            persediaan produk Anda.
          </p>
          <footer>
            <!-- <a href="#" class="button">Pelajari</a> -->
          </footer>
        </article>
        <article>
          <div class="image">
            <img
              src="https://ik.imagekit.io/bkbkl/bukabengkel/dashboard.png?updatedAt=1681744225533"
              alt="Pic 02"
              width="250px"
            />
          </div>
          <header>
            <h3>Dashboard</h3>
          </header>
          <p>
            Lihat bisnis Anda berkembang<br />
            dengan jelas melalui dashboard pintar <br />
            kami di Buka Bengkel
          </p>
          <footer>
            <!-- <a href="#" class="button">Learn More</a> -->
          </footer>
        </article>

        <article>
          <div class="image">
            <img
              src="https://ik.imagekit.io/bkbkl/bukabengkel/order_detail.png?updatedAt=1681744277436"
              alt="Pic 02"
              width="250px"
            />
          </div>
          <header>
            <h3>Detail Transaksi</h3>
          </header>
          <p>
            Mudahkan pengelolaan transaksi <br />
            dengan melihat detail transaksi lengkap <br />
            di Buka Bengkel.
          </p>
          <footer>
            <!-- <a href="#" class="button">Learn More</a> -->
          </footer>
        </article>

        <article>
          <div class="image">
            <img
              src="https://ik.imagekit.io/bkbkl/bukabengkel/checkout.png"
              alt="Pic 02"
              width="250px"
            />
          </div>
          <header>
            <h3>Bayar Sebagian</h3>
          </header>
          <p>
            Mendukung pembayaran sebagian <br />
            agar kamu lebih mudah memantau <br />
            tagihan transaksi kamu.
          </p>
          <footer>
            <!-- <a href="#" class="button">Learn More</a> -->
          </footer>
        </article>
      </div>
      <hr />
      <div class="inner">
        <h3>Tersedia Di</h3>
        <div style="margin-bottom: 20px">
          <a
            href="https://play.google.com/store/apps/details?id=com.mobile.bukabengkel"
          >
            <img src="./../assets/images/icon/playstore-icon.png" />
          </a>
        </div>
      </div>
    </div>
  </section>

  <!-- Footer -->
  <footer id="footer">
    <div class="inner">
      <h3>Hubungi Kami</h3>
      <form @submit.prevent="submitForm">
        <div class="field half first">
          <span v-if="errors.name" class="error">{{ errors.name }}</span>
          <label for="name">Name</label>
          <input
            v-model="formData.name"
            name="name"
            id="name"
            type="text"
            placeholder="Name"
          />
        </div>
        <div class="field half">
          <span v-if="errors.phone" class="error">{{ errors.phone }}</span>
          <label for="phone">No. Handphone</label>
          <input
            v-model="formData.phone"
            name="phone"
            id="phone"
            type="number"
            placeholder="No. HP"
          />
        </div>
        <div class="field">
          <span v-if="errors.message" class="error">{{ errors.message }}</span>
          <label for="message">Pesan</label>
          <textarea
            name="message"
            id="message"
            rows="6"
            placeholder="Pesan"
            v-model="formData.message"
          ></textarea>
        </div>
        <ul class="actions">
          <li>
            <input value="Send Message" class="button alt" type="submit" />
          </li>
        </ul>
        <div v-if="showToast" class="toast">
          Terima Kasih. Kami akan membalas pesan anda !
        </div>
      </form>

      <div class="copyright">bukabengkel.id &copy; 2023:</div>
    </div>
  </footer>

  <!-- Scripts -->
</template>
  
  <script>
import { mapActions } from "vuex";
export default {
  name: "LandingBukaBengkel",
  components: {},
  data() {
    return {
      formData: {
        name: "",
        phone: "",
        message: "",
      },
      errors: {},
      showToast: false,
    };
  },
  methods: {
    ...mapActions(["sendFeedback"]),
    validateForm() {
      const errors = {};
      if (!this.formData.name) {
        errors.name = "Nama Wajib di Isi";
      }
      if (!this.formData.phone) {
        errors.phone = "No. HP Wajib di Isi";
      }

      if (!this.formData.message) {
        errors.message = "Pesan Wajib di Isi";
      }
      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    submitForm() {
      if (this.validateForm()) {
        this.sendFeedback({
          name: this.formData.name,
          phone: this.formData.phone,
          feedback: this.formData.message,
        })
          .then(() => {
            this.showToast = true;
            setTimeout(() => {
              this.showToast = false;
            }, 3000);
          })
          .catch((error) => console.error(error));
      }
    },
  },
};
</script>
  
  <style src="./../assets/css/main.css"></style>
  
  <style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
  