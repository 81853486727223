import API from '../../api/index';

const state = {
  isSuccess: true,
};

const getters = {

};

const actions = {
  sendFeedback({ commit }, data) {
    state.isSuccess = false;
    return API({
      method: 'POST',
      url: '/v1/feedbacks',
      data,
    }).then(() => {
      commit("sendFeedbackSuccess");
    }).catch((err) => {
      console.log(err)
    });
  },
};

const mutations = {
  sendFeedbackSuccess: (state) => {
    state.isSuccess = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
