import API from '../../api/index';

const state = {
  activationLoading: true,
  activationError: null,
};

const getters = {
  activationLoading: (state) => state.activationLoading,
  activationError: (state) => state.activationError,
};

const actions = {
  activate({ commit }, data) {
    if (!data?.token) {
      commit("activationError", "Token Tidak Tersedia");
    }

    setTimeout(() => {
      return API({
        method: 'POST',
        url: '/v1/auths/activation/email',
        data: {
          token: data.token,
        },
      }).then(() => {
        commit("activationSuccess");
      }).catch((err) => {
        console.log(err)
        commit("activationError", err.message);
      });
    }, 100);
  },
};

const mutations = {
  activationSuccess: (state) => {
    state.activationLoading = false;
  },
  activationError: (state, error) => {
    state.activationLoading = false;
    state.activationError = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
