import * as Vue from 'vue';
import * as VueRouter from 'vue-router';
import App from './App.vue';
import Activation from './components/Activation.vue';
import Landing from './components/Landing.vue';
import ResetPassword from './components/ResetPassword.vue';
import store from './store';

const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes: [
        { path: '/', component: Landing },
        { path: '/activation', component: Activation },
        { path: '/reset-password', component: ResetPassword },
    ],
});

const app = Vue.createApp(App);

app.use(router);
app.use(store);

app.mount('#app');