<template>
  <body>
    <div class="container">
      <img src="./../assets/images/logo.png" class="logo" alt="" width="100" style="margin: 40px;" />

      <div v-if="!activationError">
        <div v-if="activationLoading">
          <div class="center">
            <gauge style="margin-right: 20px"></gauge>
            <br />
            <br />
            <br />
          </div>

          <p>Silahkan tunggu, Akun sedang di aktivasi. . .</p>
        </div>

        <div style="padding: 50pt" v-if="!activationLoading">
          <h2>Selamat Datang di Buka Bengkel !</h2>
          <p>Akun kamu telah berhasil di aktivasi !</p>
          <p>Selamat melakukan transaksi.</p>
        </div>
      </div>

      <div v-if="activationError">
        <div style="padding: 50pt" v-if="!activationLoading">
          <h2>Aktivasi Gagal</h2>
          <p>Muat ulang halaman untuk coba lagi</p>
          <p>{{ activationError }}</p>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import { Gauge } from "vue-loading-spinner";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    validation() {
      return this.userId.length > 4 && this.userId.length < 13;
    },
    ...mapGetters(["activationLoading", "activationError"]),
  },
  methods: {
    ...mapActions(["activate"]),
  },
  components: {
    Gauge,
  },
  mounted() {
    this.activate({token: this.$route.query.token});
  },
};
</script>

<style>
/* Styling untuk halaman */

.container {
  /* max-width: 600px; */
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
h2 {
  color: #e77326 !important;
  /* font-size: 28px; */
  font-weight: bold;
  /* margin-bottom: 20px; */
}
p {
  color: #e77326 !important;
  /* margin-bottom: 20px; */
}
</style>
