import * as Vuex from "vuex";
import activation from "./modules/activation";
import feedback from "./modules/feedback";
import resetPassword from "./modules/reset-password";

export default new Vuex.Store({
  modules: {
    activation,
    feedback,
    resetPassword
  }
});